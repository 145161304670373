import {dom} from 'core-utils';
import {appEvents, signal} from 'core-application';

export default class Intro extends HTMLElement {

	constructor() {
		super();
		this.globalEventBus = signal.getEmitter();
		this._handleVisibility = dom.throttle(this._handleVisibility.bind(this, true), 200);
		this._onLayerLoaded = this._onLayerLoaded.bind(this, true);
	}

	/**
	 * connectedCallback
	 * @returns {undefined}
	 */
	connectedCallback() {
		this._initializeModule();
	}

	/**
	 * disconnectedCallback
	 * @returns {undefined}
	 */
	disconnectedCallback() {
		window.removeEventListener('scroll', this._handleVisibility);
		this.globalEventBus.off(appEvents.LAYER_LOADED, this._onLayerLoaded);

		const layerWrapper = this._getLayerWrapper();
		if (layerWrapper) {
			layerWrapper.removeEventListener('scroll', this._handleVisibility);
		}
	}

	/**
	 * _initializeModule
	 * @returns {undefined}
	 */
	_initializeModule() {
		this._addEvents();
		this._handleVisibility();
	}

	/**
	 * _addEvents - private function adding events
	 * @returns {undefined}
	 */
	_addEvents() {
		window.addEventListener('scroll', this._handleVisibility);
		this.globalEventBus.on(appEvents.LAYER_LOADED, this._onLayerLoaded);
	}

	/**
	 * @returns {NodeElement} returns the node element which controls the scrolling behavior in the layer or undefined if layer is not available
	 */
	_getLayerWrapper() {
		if (typeof this.closest === "function") {
			const layer = this.closest('.nm-layer-wrapper');
			return layer;
		}

		return undefined;
	}

	/**
	 * event handler for page or layer loaded event. It tries to attach a scroll handler for the layer component.
	 * Additionally triggers refreshing the visibility of the current item
	 * @returns {undefined}
	 */
	_onLayerLoaded () {
		const layerWrapper = this._getLayerWrapper();
		if (layerWrapper){
			layerWrapper.addEventListener('scroll', this._handleVisibility);
		}

		this._handleVisibility();
	}

	/**
	 * checks if module is visible
	 * @returns {undefined}
	 */
	_handleVisibility() {
		const moduleVisible = dom.getVisibleVerticalPercentageInViewport(this);

		if (moduleVisible > 25 && !this.classList.contains('nm-j-intro-show')) {
			this.classList.add('nm-j-intro-show');
		}
	}
}

if (window.customElements.get('audi-intro') === undefined) {
	window.customElements.define('audi-intro', Intro);
}
